import ExcelJS from 'exceljs'
import { isPrimitive, isDate, unique } from 'radash'

export const isHyperlink = (value: ExcelJS.CellValue): value is ExcelJS.CellHyperlinkValue =>
  (value as ExcelJS.CellHyperlinkValue)?.hyperlink !== undefined

export const isPrimitiveValue = (value: ExcelJS.CellValue): value is string | number | boolean => isPrimitive(value)
export const isDateValue = (value: ExcelJS.CellValue): value is Date => isDate(value)

export const convertXLSToObject = async (
  arrayBuffer: ArrayBuffer,
  selectSheetFn: (sheets: ExcelJS.Worksheet[]) => Promise<string | number | undefined>,
  selectHeadlineRowIndex: (sheet: ExcelJS.Worksheet) => Promise<number | undefined>,
) => {
  const workbook = await new ExcelJS.Workbook().xlsx.load(arrayBuffer)
  const sheetId = await selectSheetFn(workbook.worksheets)
  if (sheetId === undefined) {
    return undefined
  }
  const sheet = workbook.getWorksheet(parseInt(sheetId as string))
  const result = Array.from(new Array(sheet!.rowCount))
  let maxCellCount = 0
  sheet!.eachRow((row, rowNumber) => {
    result[row.number - 1] = Array.from(new Array(row.cellCount))
    maxCellCount = row.cellCount > maxCellCount ? row.cellCount : maxCellCount
    row.eachCell(
      (cell, number) =>
        (result[rowNumber - 1][number - 1] = isHyperlink(cell.value)
          ? cell.value?.hyperlink
          : isPrimitiveValue(cell.value)
            ? `${cell.value}`.trim()
            : isDate(cell.value)
              ? cell.value.toDateString()
              : ''),
    )
  })

  // add images if there are any
  const images = sheet!.getImages()
  images
    // remove images without coordinates
    .filter((image) => image.range.br || image.range.tl)
    // use either tl or br anchor
    .map((image) => [image.imageId, image.range.br || image.range.tl] as [string, ExcelJS.Anchor])
    .forEach(
      ([imageId, anchor]) =>
        (result[anchor.nativeRow][anchor.nativeCol] =
          'data:image/png;base64,' + workbook.getImage(parseInt(imageId)).buffer?.toString('base64')),
    )

  const headlineRowIndex = await selectHeadlineRowIndex(sheet!)
  if (headlineRowIndex === undefined) {
    return undefined
  }
  const keys = [...result[headlineRowIndex - 1]]
  const filteredResult = result.slice(headlineRowIndex, result.length).filter(
    (row) =>
      row &&
      // remove rows that are most likely headline rows
      unique<string, string>(row).filter((cell: string | undefined) => !!cell).length > 3,
  )

  const data = filteredResult.map((row) =>
    Object.fromEntries(row.map((cell: string, index: number) => [keys[index], cell])),
  )

  return { keys, data }
}
