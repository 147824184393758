<template lang="pug">
div(class='pagination-compact') 
  div(:data-tooltip='$t("Previous Page")')
    btn(:disabled='disabledComputed || (!loop && current === 1)' plain tiny icon='arrow-left' @click='() => (current = current > 1 ? current - 1 : pages.length)')
  div(:data-tooltip='$t("Next Page")')
    btn(:disabled='disabledComputed || (!loop && current === pages.length)' plain tiny icon='arrow-right' @click='() => (current = current >= pages.length ? 1 : current + 1)')
  control-select#pagination-select(v-if='!noSelectBox' v-model='current' plain inline tiny :options='pagesAsOptions' size='small' :disabled='disabledComputed || total < pageSize')
  div(v-else) {{ current }} / {{ pages.length }}
</template>

<script lang="ts">
import Btn from './Btn.vue'
import ControlSelect from './Control/ControlSelect.vue'
import { useTranslation } from '@/plugins/translation'
import type { IOption } from '@/types'
import { useVModel } from '@vueuse/core'
import { defineComponent, computed, type SetupContext } from 'vue'

const PaginationCompact = defineComponent({
  components: { ControlSelect, Btn },
  props: {
    total: { type: Number, required: true },
    modelValue: { type: Number, required: true },
    loop: Boolean,
    disabled: Boolean,
    noSelectBox: Boolean,
    pageSize: { type: Number, default: 6 },
  },
  setup(props, context: SetupContext) {
    const { $t } = useTranslation()
    const disabled = computed(() => props.disabled || !pages.value || pages.value.length < 2)
    const current = useVModel(props, 'modelValue', context.emit)

    const pages = computed(() =>
      Array.from(Array(Math.ceil((props.total === 0 ? 1 : props.total) / props.pageSize)).keys()).map(
        (i: number) => ++i,
      ),
    )

    const pagesAsOptions = computed<IOption[]>(() =>
      pages.value.map((value: number) => ({
        value: value,
        label: $t('{0} / {1}', '', [value, pages.value.length]),
      })),
    )

    return {
      current,
      disabledComputed: disabled,
      pages,
      pagesAsOptions,
    }
  },
})
export default PaginationCompact
</script>
<style lang="stylus">
.pagination-compact
  display: flex
  font-size: inherit
  gap: 3px
  align-items: center
  div
    white-space: nowrap
</style>
