// @ts-expect-error
import * as csvjson from 'csvjson'

export const guessDelimiter = (csvAsText: string) => {
  const firstLine = csvAsText.split('\n')[0]
  return firstLine.split(',').length > firstLine.split(';').length ? ',' : ';'
}

export const convertCSV = (rawCSVString: string, delimiter: string = ';', minFieldNum: number = 3) => {
  let returnValue
  try {
    returnValue = csvjson
      .toObject(rawCSVString.replaceAll(/("[^"\n]*)\r?\n(?!(([^"]*"){2})*[^"]*$)/g, '$1 '), {
        delimiter,
        quote: '"',
      })
      .filter((line: any) => Object.values(line).filter((v) => !!v).length > minFieldNum)
  } catch (e) {
    returnValue = []
  }
  return returnValue
}

export const convertCSVToObject = async (csvString: string) => {
  const delimiter = guessDelimiter(csvString)
  const data = convertCSV(csvString, delimiter)
  const keys = Object.keys(data[0])
  return { keys, data }
}
