<template lang="pug">
div(class='bleed relative mb-5 flex min-h-0 flex-1 flex-col')
  div(class='unbleed flex flex-col')
  overlay(:visible='loading')
  drilldown(:modelValue='index' class='flex w-full flex-1')
    drilldown-item(:id='1')
      div(class='unbleed')
        p(
          class='prose mb-6 max-w-full text-sm text-text-light'
          v-html='$t("Ordne hier die Felder aus deiner CSV- oder Excel-Datei unseren Feldern zu. Wichtig ist dabei, dass Felder für <b>Produktname</b>, <b>Produktnummer</b> und <b>Hersteller</b> vorhanden sind. So finden Deine Daten den richtigen Weg in unsere Plattform.")'
        )
        table(class='w-full max-w-full')
          tr
            th(class='w-[70%] pb-3 font-lato text-base font-bold') {{ $t('Felder aus Datei') }}
            th(class='pb-3 font-lato text-base font-bold') {{ $t('nuucon Felder') }}
            th
          template(v-for='field in externalProductFields' :key='field.key')
            tr(class='border-b-[2px] border-b-white')
              td(class='pr-2 align-top')
                div(class='flex items-center')
                  control-label(class='!m-0 !p-0')
                    control-multi-select(v-model='fieldMap[field.key]' :options='options' :searchable='false' :close-on-select='false')
                      template(#aside='{ selectedOptions }')
                        div(class='relative top-0 ml-2 flex-1 rounded-r bg-grey-75' @click.prevent.stop='() => false')
                          div(class='sticky left-0 top-0 w-full overflow-auto p-3')
                            div(v-for='option in selectedOptions' :key='option.value')
                              div(class='flex text-sm font-bold')
                                div(class='flex-1') {{ $t('Vorschau') }}
                                pagination-compact(v-model='sampleIndex' compact loop class='text-xs font-normal' :total='data.length' :pageSize='9' no-select-box)
                              code(class='text-sm font-light') !{ ' ' } ({{ option.value }})
                              div(v-for='(item, index2) in getPreviewValuesFromOption(option).slice(sampleIndex - 1, sampleIndex + 9 - 1)' :key='item.value' class='bg-grey-75 pt-2')
                                hr
                                code(class='my-3 overflow-auto break-all text-xs text-text-light')
                                  span(class='text-text-light') {{ (sampleIndex - 1) * 9 + index2 + 1 }}. !{ ' ' }
                                  span(v-if='!isImage(item)') {{ item || '-' }}
                                  img(v-else :src='item' class='mix-blend-multiply')
                  icon(name='arrow-right' class='ml-3')
              td(class='pr-4 align-top')
                div(class='flex items-center')
                  control-input(disabled :modelValue='$t(field.key)')
              th
                tooltip-button(v-if='fieldMap[field.key]' plain-success popoverType='success' icon='custom-check-circle' class='ml-1' small :text='$t("Feld zugeordnet.")' :debounce='10')
                tooltip-button(v-else-if='field.description' class='ml-1' small :text='$t(field.description)' :debounce='10')
                tooltip-button(
                  v-if='field.required && !fieldMap[field.key]'
                  plain-danger
                  popoverType='danger'
                  icon='alert-triangle'
                  class='ml-1'
                  small
                  :text='$t("Dieses Feld wird benötigt.")'
                  :debounce='10'
                )
          tr
            td(colspan='3')
              div(class='my-2 flex flex-wrap gap-4')
                btn(link link-inline icon='rotate-cw' :disabled='isFieldMapEmpty' @click='resetMapping') {{ $t('Felder zurücksetzen') }}
                btn(link link-inline icon='zap' @click='guessFields') {{ $t('Felder automatisch zuordnen') }}
                btn(v-if='!isCSV' link link-inline icon='table' @click='() => init(false, true)') {{ $t('Datenblatt/Tabellenkopfzeile neu zuordnen') }}
    drilldown-item(:id='2')
      div(v-if='mappedProducts.length' class='mb-5 flex-1')
        div(class='unbleed')
          p(
            class='prose mb-6 max-w-full text-sm text-text-light'
            v-html='$t("Wähle hier die Produkte aus die zu  Projekt oder Anfrage hinzugefügt werden sollen. Die ausgewählten Produkte werden als Wunschprodukte angelegt und können innerhalb des Projekts nachträglich bearbeitet werden.")'
          )
        custom-data-table(v-model='selectedItems' :items='mappedProducts' :config='config' type='multiselect')
          template(#before='{ selected }')
            checkbox-icon(:value='selected')
          template(#ImageUrl='{ value }')
            div(class='w-[50px]')
              img(v-if='value' class='mix-blend-multiply' :src='value')
              img(v-else class='rounded bg-grey-75' src='/images/nuucon-image-placeholder.svg')
          template(#Name='{ item }')
            div(class='break-all text-sm text-text-light') {{ item.Brand }}
            div
              b {{ item.Name }}
            div(v-if='item.productUrl')
              a(:href='item.productUrl' class='text-sm') {{ item.productUrl }}
            text-auto-expand(:text='item.Description' class='whitespace-pre-wrap text-sm' :maxNewLines='5')
          template(#Quantity='{ value }')
            div {{ value }}
          template(#Price='{ value }')
            div {{ formatPrice(value) }}
sticky-container(enabled position='bottom' class='bleed py-4')
  div(class='unbleed')
    notification(
      v-if='errorneousFields.length'
      type='error'
      class='my-5'
      :message='$t("Die folgenden Felder enthalten Fehler oder sind nicht in jedem Produkt vorhanden: <b>{0}</b>", "", [errorneousFields.map((v) => $t(v)).join(", ")])'
    )
    action-bar(v-if='index === 1')
      template(#left)
        btn(tertiary @click='$emit("close")') {{ $t('close') }}
      btn(secondary medium icon='arrow-left' @click='resetWithConfirm()') {{ $t('back') }}
      btn(medium icon='arrow-right' icon-right :disabled='loading || !data.length || notAllRequiredFieldsMapped' @click='index = 2') {{ $t('Weiter zur Vorschau und Auswahl') }}

    action-bar(v-else)
      template(#left)
        btn(tertiary @click='$emit("close")') {{ $t('close') }}

      btn(medium secondary icon='arrow-left' @click='index = 1') {{ $t('back') }}
      btn(:loading='loading' medium :disabled='loading || !data.length || !mappedProducts.length || !selectedItems.length' @click='submit')
        span(v-html='$tc("Add {0} product(s)", selectedItems.length)')
</template>

<script setup lang="ts">
import type { IFormResponse } from '@/components/GenericForm/types'
import type { ITableConfig } from '@/components/Table/CustomDataTable.vue'
import { thorFormPostPromise, useGlobalCsvOrXlsImportState } from '@/composables/'
import { useConfirm } from '@/plugins/Confirm'
import { useTranslation } from '@/plugins/translation'
import { type IOption } from '@/types'
import { addImageFromUrl, addImageFromB64 } from '@/utilities'
import { formatPrice, parseNumber } from '@/utilities'
import { convertCSVToObject } from '@/utilities/Csv'
import { convertXLSToObject } from '@/utilities/Xls'
import ExcelJS from 'exceljs'
import Fuse from 'fuse.js'
import { mapEntries, objectify, snake } from 'radash'
import { computed, ref } from 'vue'
import { onMounted } from 'vue'

type ExternalProductFormFields =
  | 'id'
  | 'ProductUrl'
  | 'Name'
  | 'Quantity'
  | 'Brand'
  | 'ProductNumber'
  | 'Price'
  | 'IsNet'
  | 'ImageUrl'
  | 'Tag'
  | 'Description'
  | 'S3Path'

interface IExternalProductField {
  key: ExternalProductFormFields
  translations: string[]
  description?: string
  required?: boolean
  defaultValue?: string | number
  convert?: (value: any) => any
}

const externalProductFields: IExternalProductField[] = [
  { key: 'Name', translations: ['Name', 'Titel', 'Description Short'], required: true },
  { key: 'Brand', translations: ['Brand', 'Hersteller', 'Marke', 'Manufacturer'], required: true },
  {
    key: 'ProductNumber',
    translations: ['ProductNumber', 'Produkt Nummer', 'EAN', 'Artikelnummer', 'Article No'],
  },
  { key: 'ProductUrl', translations: ['ProductUrl', 'Produkt Link', 'Adresse', 'Link'], defaultValue: '-' },
  {
    key: 'Price',
    translations: ['Price', 'Preis', 'UVP', 'EK', 'EP', 'Verkaufspreis'],
    convert: parseNumber,
    defaultValue: 0,
  },
  { key: 'ImageUrl', translations: ['ImageUrl', 'image', 'Bild', 'Foto'] },
  { key: 'Description', translations: ['Description', 'Info', 'Beschreibung', 'Bezeichnung', 'Langtext', 'Maße'] },
  { key: 'Quantity', translations: ['Quantity', 'Anzahl', 'Menge'], convert: parseInt, defaultValue: 0 },
  { key: 'Tag', description: 'Room, level, or similar', translations: ['Tag', 'Kategorie', 'Category'] },
]

const translations = {
  ProductUrl: 'Produkt Link',
  Name: 'Produktname',
  Quantity: 'Anzahl',
  Brand: 'Marke',
  ProductNumber: 'Produktnummer',
  Price: 'Preis (Netto)',
  ImageUrl: 'Bild',
  Result: 'Ergebnis',
  'reset mapping': 'Zuweisungen zurücksetzen',
  'CSV Fields': 'Felder aus CSV-Datei',
  'Nuucon Fields': 'nuucon Felder',
  Tag: 'Liste',
  'Room, level, or similar':
    'Hier falls vorhanden Kategorien wie Raum, Geschoss oder Stil auswählen. Es werden dafür automatisch Listen innerhalb des Projektes angelegt.',
  Description: 'Beschreibung',
}

const props = defineProps({
  projectId: { type: String, required: true },
  inquiryId: { type: String },
  file: { type: File, required: true },
  newFile: Boolean,
})
defineOptions({
  inheritAttrs: false,
})
const { promptSelect, prompt, confirm } = useConfirm()
const { $t, add } = useTranslation()
add(translations)

const emit = defineEmits(['close', 'reset'])
let isCSV = true

const getInitialFieldMap = () => Object.fromEntries(externalProductFields.map((field) => [field.key, '']))
const { data, keys, fieldMap } = useGlobalCsvOrXlsImportState(getInitialFieldMap())
const externalProductFieldMap = computed(() => objectify(externalProductFields, (item) => item.key))
const options = computed(() => [{ value: '', label: '-' }, ...keys.value.map((key) => ({ value: key, label: key }))])
const mappedProducts = computed<Record<ExternalProductFormFields, any>[]>(() =>
  data.value.map((rawData: Record<string, string>, index: number) =>
    Object.fromEntries([
      ['id', index],
      ...externalProductFields.map((item) => [
        item.key,
        rawData[fieldMap.value[item.key]] !== undefined
          ? item.convert
            ? item.convert(rawData[fieldMap.value[item.key]])
            : rawData[fieldMap.value[item.key]]
          : item.defaultValue ?? '',
      ]),
    ]),
  ),
)

const loading = ref(false)
const index = ref<number>(1)
const sampleIndex = ref<number>(1)
const guessFields = () => {
  const fuse = new Fuse(keys.value, {
    isCaseSensitive: false,
    includeScore: true,
    threshold: 0.3,
    ignoreLocation: true,
    shouldSort: true,
  })
  const multiSearch = (terms: string[]) =>
    terms.flatMap((term) => fuse.search(term)).sort((a, b) => a.score! - b.score!)
  fieldMap.value = mapEntries(fieldMap.value, (key, value) => [
    key,
    multiSearch(externalProductFieldMap.value[key as ExternalProductFormFields]!.translations)?.[0]?.item || value,
  ])
}

const selectedItems = ref<string[]>([])

const notAllRequiredFieldsMapped = computed(() =>
  externalProductFields.some((f) => f.required && !fieldMap.value[f.key]),
)
const errorneousFields = ref<string[]>([])

const resetMapping = () => {
  fieldMap.value = getInitialFieldMap()
  errorneousFields.value = []
}

const reset = () => {
  index.value = 1
  selectedItems.value = []
  data.value = []
  keys.value = []
  resetMapping()
  emit('reset')
}

const resetWithConfirm = () => {
  confirm({
    content: $t('Auswahl und Zuordnungen werden dabei zurückgesetzt.'),
    onOk: () => {
      reset()
    },
  })
}

let result: undefined | { keys: string[]; data: any } = undefined
const init = (resetOnAbort: boolean = true, force?: boolean) => {
  if (keys.value.length !== 0 && !force) {
    return
  }
  loading.value = true
  const reader = new FileReader()
  reader.onload = async () => {
    result = isCSV
      ? await convertCSVToObject(reader.result as string)
      : await convertXLSToObject(
          reader.result as ArrayBuffer,
          async (sheets: ExcelJS.Worksheet[]) => {
            const options: IOption[] = sheets.map((sheet) => ({ label: sheet.name, value: `${sheet.id}` }))
            const id =
              options.length > 1
                ? await promptSelect(
                    $t('Datenblatt auswählen'),
                    $t('Datenblatt'),
                    options,
                    options[0].value as string,
                    undefined,
                    { content: $t('Wähle das Datenblatt mit den Produkten aus.') },
                  )
                : (options[0].value as string)
            return id
          },
          async (sheet: ExcelJS.Worksheet) =>
            ((await prompt(
              $t('Tabellenkopf Zeile'),
              $t('Zeile'),
              1,
              { min: 1, max: sheet.rowCount, type: 'number' },
              false,
              { content: $t('In welcher Zeile befindet sich die Spaltenbezeichner (Tabellenkopf)?') },
            )) as number) || undefined,
        )

    if (result === undefined) {
      if (resetOnAbort) {
        reset()
      }
    } else {
      keys.value = result!.keys as string[]
      data.value = result!.data
      guessFields()
      index.value = 1
      selectedItems.value = []
    }
    loading.value = false
  }
  isCSV = props.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  if (isCSV) {
    reader.readAsText(props.file)
  } else {
    reader.readAsArrayBuffer(props.file)
  }
}
onMounted(() => init(true, props.newFile))

const isFieldMapEmpty = computed(() => Object.values(fieldMap.value).every((v) => v === ''))
const isImage = (value: string) => ['data:image', '.png', '.jpg', '.jpeg'].some((v) => value?.includes(v))
const getPreviewValuesFromOption = (option: IOption) => data.value.map((row) => row[option.value as string])
const submit = async () => {
  loading.value = true
  const mappedProductsMap = objectify(mappedProducts.value, (item) => item.id)
  errorneousFields.value = []
  try {
    for (const id of selectedItems.value) {
      const p = mappedProductsMap[id]
      try {
        if (p.ImageUrl) {
          p.S3Path = isCSV ? await addImageFromUrl(p.ImageUrl) : await addImageFromB64(snake(p.Name), p.ImageUrl)
        }
      } catch (e) {}
      await thorFormPostPromise(
        `/_/AddOrEditExternalProjectItem/AddOrEditExternalProjectListItemForm/AddOrUpdateProductListItem?projectId=${
          props.projectId
        }${p.Tag ? '&tag=' + p.Tag : ''}&productId=*`,
        { ...p, IsNet: true },
      )
    }
    reset()
    emit('close')
  } catch (e: any) {
    const response = e.response as IFormResponse
    errorneousFields.value = Object.entries(response.form?.items || [])
      .filter(([, field]) => field.notifications?.length)
      .map(([key]) => key)
  }
  loading.value = false
}

const config = computed<ITableConfig>(() => ({
  ImageUrl: { label: '', sortable: false, classes: 'image w-0' },
  Name: { label: $t('Title'), sortable: false },
  Quantity: { label: $t('Quantity'), sortable: false },
  Price: { label: $t('retailPriceNetString'), sortable: false, classes: 'text-right' },
}))
</script>
